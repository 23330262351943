import {getChildTreeListByParentId, getParentTreeByid} from "A/jcgn";
import {getItemFromArrayByKey, getTreeDeepString} from "U";

export const userdep = {
    created() {
    },
    methods: {
        initUserDeptid(userdepid) {
            let params = {
                userdepid
            }
            getParentTreeByid(params).then(res => {
                if(res && res.returncode == '0') {
                    let deepUserdepid = getTreeDeepString(res.item, '', 'userdepid', 'childUserdep');
                    let deepUserdepList = deepUserdepid.split('|');
                    if(deepUserdepList.length > 1) {
                        this.initUserDeptListTree(deepUserdepList, 0, deepUserdepList);
                    }else {
                        this.modalForm.selectDeptList = deepUserdepList;
                    }
                }
            })
        },
        initUserDeptListTree(arr, index, deepUserdepList) {
            if(index < arr.length-1) {
                let parentid = arr[index];
                let targetOption = getItemFromArrayByKey(this.deptOptions, 'userdepid', parentid);
                let params = {
                    parentid
                }
                getChildTreeListByParentId(params).then(res => {
                    // targetOption.loading = false;
                    if(res && res.returncode == '0') {
                        targetOption.children = res.item.map(item => {
                            item.userdepid = item.userdepid.toString();
                            item.isLeaf = item.childsize === 0;
                            return item;
                        });
                        // this.userDeptList = [...this.userDeptList]
                        this.initUserDeptListTree(arr, index+1, deepUserdepList)
                    }
                }).catch(err => {
                    // targetOption.loading = false;
                })
            }else {
                this.modalForm.selectDeptList = deepUserdepList;
            }
        },
    }
}
